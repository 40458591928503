/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2023
 */

import React from "react";
import "./AppFooter.scss";

const AppFooter = () => {

    return (
        <div className="AppFooter">
            <footer>
                <div>
                    <p>Beligent ©2023</p>
                    <p>Zurich, Switzerland</p>
                </div>
                <div>
                    <p>contact(at)beligent.io</p>

                </div>
            </footer>
        </div>
    );
};

AppFooter.propTypes = {};

AppFooter.defaultProps = {};

export default AppFooter;
