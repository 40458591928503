/**
 * @title 
 * @author Beligent
 * @notice 
 * Copyright 2023
 */

import React, { useState } from "react";
import "./Minting.scss";
import nftMinting from "./../../ABI/NftMinting.json";
import mintingAnimation from "./../../assets/nyan_cat.gif";

import { ethers } from "ethers";

const Minting = ({ user, onMinted }) => {
    // console.log("Minting Component");
    // console.log(user);

    /* Code for when we want to store further than the component's life time
    // local state is initialized by local storage
    // const [minted, setMinted] = useState(() => {
    //     const saved = localStorage.getItem("_beligent_minted");
    //     return saved ? JSON.parse(saved) : false;
    // });
    // // Local storage updates when component state is updated
    // useEffect(() => {
    //     localStorage.setItem("_beligent_minted", JSON.stringify(minted));
    // }, [minted]);
    */

    const [nftId, setNftId] = useState("");
    const [minting, setMinting] = useState(false);
    const [minted, setMinted] = useState(false);
    const [txnHash, seTxnHash] = useState("");

    const collectionId = process.env.REACT_APP___DEPRECATED__MINTING_CONTRACT_ADDRESS;
    let tokenId = "";


    /*** METHODS *************************************************************/

    // Setup our listener.
    const smartContractListener = async () => {
        try {
            const { ethereum } = window;

            if (ethereum && !minted && !minting) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();
                const connectedContract = new ethers.Contract(
                    collectionId!,
                    nftMinting.abi,
                    signer
                );

                // NFT Hook for minting successful feedback
                connectedContract.on("NewNFTMinted", (from, nftId) => {
                    tokenId = nftId.toBigInt().toString(); // conversion from bigInt to string
                    console.log("NewNFTMinted event! Request for NFT " + tokenId + " by user " + from);
                    console.log(
                        `opensea: https://testnets.opensea.io/assets/sepolia/${collectionId}/${tokenId}`
                    );
                    console.log(
                        `etherscan: https://sepolia.etherscan.io/nft/${collectionId}/${tokenId}`
                    );

                    setMinting(false);
                    setMinted(true);
                    setNftId(tokenId);

                    console.log(tokenId);

                    onMinted(collectionId, tokenId);

                });
            } else {
                // console.log("");
            }
        } catch (error) {
            console.log(error);
        }
    };


    const { ethereum } = window;
    if (!ethereum) {
        return;
    }

    const blockchainMakeNft = async () => {
        try {
            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();

                const connectedContract = new ethers.Contract(
                    collectionId!,
                    nftMinting.abi,
                    signer
                );

                console.log("Going to pop wallet now to pay gas...");
                let nftTxn = await connectedContract.makeGenericNft();
                
                setMinting(true);
                console.log("Minting...please wait.");

                smartContractListener();

                await nftTxn.wait();
                console.log("Minting transaction completed");
                console.log(nftTxn);
                seTxnHash(nftTxn.hash);

                console.log(
                    `Minted, see transaction: https://sepolia.etherscan.io/tx/${nftTxn.hash}`
                );
            } else {
                console.log("Ethereum object doesn't exist!");
            }
        } catch (error) {
            console.log(error);
        }
    };

    /*** RENDERING ***********************************************************/

    const renderMintNft = () => (
        <div>
            <p>Mint a test NFT here:</p>
            <button
                onClick={blockchainMakeNft}
                className="cta-button mint-button"
            >
                Mint NFT
            </button>
        </div>
    );

    const renderMinting = () => (
        <>
            <div>
                <img src={mintingAnimation} width="200px" />
            </div>
            <div>The NFT is being recorded on the blockchain...</div>
        </>
    );

    const renderNotConnectedContainer = () => (
        <div>Wallet is not connected.</div>
    );

    // TODO remove h2
    const renderWelcomeMessage = () => (
        <div className="welcome-message">
            <h2>NFT minting</h2>
        </div>
    );

    const renderSeeTransaction = () => (
        <div>
            <p>
                Transaction on etherscan:&nbsp;
                <a
                    href={`https://sepolia.etherscan.io/tx/${txnHash}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {txnHash}
                </a>
            </p>
        </div>
    );

    const renderSeeNft = () => (
        <div>
            <p>The NFT has been minted and sent to your wallet.</p>
            <p>
                See it on OpenSea:
                <a
                    href={`https://testnets.opensea.io/assets/sepolia/${collectionId}/${nftId}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    https://testnets.opensea.io/assets/sepolia/{collectionId}/{nftId}
                </a>
                <br></br>
                Or on&nbsp;
                <a
                    href={`https://sepolia.etherscan.io/nft/${collectionId}/${nftId}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    etherscan
                </a>.
            </p>
        </div>
    );

    return (
        <div className="Minting">
            {(() => {
                if (user?.publicAddress !== "") {
                    return <>{renderWelcomeMessage()}</>;
                } else {
                    return <>{renderNotConnectedContainer()}</>;
                }
            })()}
            {(() => {
                if (user?.publicAddress !== "" && !minting && !minted) {
                    return <>{renderMintNft()}</>;
                }
            })()}

            {(() => {
                if (user?.publicAddress !== "" && minting && !minted) {
                    return <>{renderMinting()}</>;
                }
            })()}

            {(() => {
                if (user?.publicAddress !== "" && txnHash) {
                    return <>{renderSeeTransaction()}</>;
                }
            })()}

            {(() => {
                if (user?.publicAddress !== "" && minted) {
                    return <>{renderSeeNft()}</>;
                }
            })()}
        </div>
    );
};

Minting.propTypes = {};

Minting.defaultProps = {};

export default Minting;
