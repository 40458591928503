/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2023
 */

import React, { useEffect, useState } from "react";
import "./Platform.scss";
import Minting from "./../../Minting/Minting.tsx";

import CertificateMaker from "./../../CertificateMaker/CertificateMaker.tsx";

const Platform = ({ user }) => {
    const userConnected = user && user?.publicAddress !== "";
    console.log("platform Component");
    console.log(user);

    const [tokenId, setTokenId] = useState("");
    const [collectionId, setCollectionId] = useState("");

    // local state is initialized by local storage
    // useless
    // const [connectedToMintingContract, setConnectedToMintingContract] =
    //     useState(() => {
    //         const saved = localStorage.getItem(
    //             "_beligent_connectedToMintingContract"
    //         );
    //         return saved ? JSON.parse(saved) : false;
    //     });
    // // Local storage updates when component state is updated
    // useEffect(() => {
    //     localStorage.setItem(
    //         "_beligent_connectedToMintingContract",
    //         JSON.stringify(connectedToMintingContract)
    //     );
    // }, [connectedToMintingContract]);

    // local state is initialized by local storage
    const [minted, setMinted] = useState(() => {
        const saved = localStorage.getItem("_beligent_minted");
        return saved ? JSON.parse(saved) : false;
    });
    // Local storage updates when component state is updated
    useEffect(() => {
        localStorage.setItem("_beligent_minted", JSON.stringify(minted));
    }, [minted]);

    /* <h1>Welcome to Beligent's website</h1> */
    const renderDefaultMessage = () => (
        <div className="welcome-message">
            <h3 className="">Welcome to Beligent's portal</h3>
        </div>
    );

    const renderWelcomeMessage = () => (
        <div className="">
            <br />
            <br />
            <h3 className="">Certificate platform</h3>
            <br />
            <p>Welcome {user.name}</p>
            <br />
        </div>
    );

    // TODO remove h2
    const renderMintingComponent = () => (
        <>
            <h2>NFT metadata</h2>
            Certified NFTs need some metadata that informs about the existence
            of the certificate. We recommend at least a combination of 2
            displays among the 3 following ones:
            <ul className="unnumbered-list">
                <li>
                    Perhaps the most visible place is in the description, at the
                    end as an additional sentence.
                </li>
                <li>
                    As an OpenSea compatible NFT trait, a small JSON object.
                </li>
                <li>As a complete descriptive JSON object.</li>
            </ul>
            <p>An example of NFT metadata containing the 3 displays.</p>
            <code>
                {"{"}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;"name": {"<"}THE_NAME_OF_YOUR_NFT
                {">"}",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;"description": "{"<"}
                THE_DESCRIPTION_OF_YOUR_NFT{">"} This NFT's authenticity and
                ownership can be verified at www.beligent.io/validator",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;"certificate": {"{"}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"issuer":
                "Beligent",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"check":
                "www.beligent.io/validator",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"description":
                "This NFT is linked to a certificate of authenticity and
                ownership issued by Beligent and stored on a blockchain. To
                verify it, follow instructions on www.beligent.io."
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;{"}"},<br />
                &nbsp;&nbsp;&nbsp;&nbsp;"image": "{"<"}
                THE_LOCATION_OF_THE_NFT_IMAGE{">"}",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;"attributes": [<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"{"}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"trait_type":
                "Certificate Authority",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"value":
                "Beligent"
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"}"},<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"{"}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"trait_type":
                "Certificate check",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"value":
                "www.beligent.io/validator"
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"}"}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;]
                <br />
                {"}"}
                <br />
            </code>
            <Minting user={user} onMinted={onMintedCallback} />
        </>
    );

    const renderCertificateMakerComponent = () => (
        <CertificateMaker
            nftMinted={minted}
            user={user}
            tokenId={tokenId}
            collectionId={collectionId}
        />
    );

    const onMintedCallback = (collectionId: string, tokenId: string) => {
        console.log("onMintedCallback");
        console.log("tokenId", tokenId);
        console.log("collectionId", collectionId);
        setMinted(true);
        setTokenId(tokenId);
        setCollectionId(collectionId);
        console.log(user?.publicAddress);
    };

    /* NOT SURE THIS IS NEEDED */
    useEffect(() => {
        const { ethereum } = window;

        if (!ethereum) {
            console.log("Make sure you have metamask!");
            return;
        } else {
            // console.log("We have the ethereum object", ethereum);
        }
    }, []);

    return (
        <div className="Platform">
            {(() => {
                if (userConnected) {
                    return (
                        <div>
                            {renderWelcomeMessage()}
                            {/* {renderMintingComponent()}
                            {renderCertificateMakerComponent()} */}
                        </div>
                    );
                } else {
                    return <div>{renderDefaultMessage()}</div>;
                }
            })()}
        </div>
    );
};

Platform.propTypes = {};

Platform.defaultProps = {};

export default Platform;
