/**
 * @title 
 * @author Beligent
 * @notice 
 * Copyright 2023
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import "./CertificateMaker.scss";
import { ethers } from "ethers";
import abiCertificateMaker from "./../../ABI/RoaManagerV1.json";

import inProgressAnimation from "./../../assets/progress.gif";

const CertificateMaker = ({ nftMinted, user, tokenId, collectionId }) => {
    const certificateContractAbi = abiCertificateMaker.abi;
    let certificateContract;
    let provider;
    let signer;

    const nftCreatorName = "Beligent";

    const [verifiedNftIssuerAddress, setVerifiedNftIssuerAddress] =
        useState("");
    const [verifiedNftIssuerName, setVerifiedNftIssuerName] = useState("");
    const [
        verifiedNftCertificateTimestamp,
        setVerifiedNftCertificateTimestamp,
    ] = useState("");

    const [certificateMined, setCertificateMined] = useState(false);
    const [certificateMining, setCertificateMining] = useState(false);

    /* Code for when we want to store further than the component's life time
    // local state is initialized by local storage
    const [certificateDone, setCertificateDone] = useState(() => {
        const saved = localStorage.getItem("_beligent_certificateDone");
        return saved ? JSON.parse(saved) : false;
    });
    // Local storage updates when component state is updated
    useEffect(() => {
        localStorage.setItem(
            "_beligent_certificateDone",
            JSON.stringify(certificateDone)
        );
    }, [certificateDone]);
    */

    /*** METHODS *************************************************************/

    /**
     * Listens in for event
     */
    const smartContractListener = async () => {
        if (ethereum) {
            if (!certificateMined && !certificateMining) {
                console.log("Waiting for certificate");
                // if (connectedToMintingContract) {
                //     setConnectedToMintingContract(true);
                // const onCertificateHasBeenRecorded = (from, timestamp, message) => {
                //     console.log("CertificateHasBeenRecorded", from, timestamp, message);
                // };

                console.log("ethereumethereum");
                provider = new ethers.providers.Web3Provider(ethereum);
                signer = provider.getSigner();

                console.log("signer useEffect");
                console.log(signer);
                certificateContract = new ethers.Contract(
                    process.env.REACT_APP___DEPRECATED__CERTIFICATE_CONTRACT_ADDRESS!,
                    certificateContractAbi,
                    signer
                );

                // Certificate hook indicates positive transaction record
                certificateContract.on(
                    "CertificateHasBeenRecorded",
                    (from, creatorName, timestamp) => {
                        console.log("CertificateHasBeenRecorded event received");
                        console.log(from, creatorName, timestamp);

                        setVerifiedNftIssuerAddress(from);
                        setVerifiedNftIssuerName(creatorName);

                        var dateFormat = new Date(timestamp.toNumber() * 1000);
                        setVerifiedNftCertificateTimestamp(
                            dateFormat.toLocaleDateString() +
                                " " +
                                dateFormat.toLocaleTimeString()
                        );

                        setCertificateMining(false);
                        setCertificateMined(true);
                    }
                );
            }
        } else {
            console.log("Ethereum object doesn't exist!");
        }
    };

    /**
     * Makes an hard-coded request to the smart contract
     */
    const makeCertificate = async () => {
        console.log("-> makeCertificate");

        provider = new ethers.providers.Web3Provider(ethereum);
        signer = provider.getSigner();

        // console.log("signer");
        // console.log(signer);

        certificateContract = new ethers.Contract(
            process.env.REACT_APP___DEPRECATED__CERTIFICATE_CONTRACT_ADDRESS!,
            certificateContractAbi,
            signer
        );

        // console.log("certificateContract");
        // console.log(certificateContract);

        console.log("Going to pop wallet now to pay gas...");
        console.log("NFT creator address: " + user?.publicAddress);
        console.log("NFT creator name: " + nftCreatorName);
        console.log("NFT collection address: " + collectionId);
        console.log("NFT token ID: " + tokenId);
        const transactionCertificate1 = await certificateContract
            .connect(signer)
            .makeCertificate(
                user?.publicAddress,
                nftCreatorName,
                collectionId,
                tokenId
            );

        setCertificateMining(true);

        smartContractListener();
        console.log("Mining...please wait.");
        await transactionCertificate1.wait();

        console.log("makeCertificate operation done");

        // Getter
        console.log("-> getNbrOfCertificates");
        const nbrOfCertificates =
            await certificateContract.getNbrOfCertificates();
        console.log("nbrOfCertificates:");
        console.log(nbrOfCertificates.toNumber());


        // Getter
        console.log("-> getCertificate");
        const certificateDetails =
            await certificateContract.getCertificate(
                collectionId,
                tokenId);
        console.log("certificateDetails:");
        console.log(certificateDetails);
    };

    /*** RENDERING ***********************************************************/
    const renderWelcomeMessage = () => (
        <div className="welcome-message">
            <h2>Certificate crafting</h2>
        </div>
    );

    const renderResultSuccessMessage = () => (
        <div className="section-content">
            <span>Certificate is mined</span>
            <div className="certificate-preview">
                <span>Issuer address: </span>
                <span>{verifiedNftIssuerAddress}</span>
                <br></br>
                <span>Issuer name: </span>
                <span>{verifiedNftIssuerName}</span>
                <br></br>
                <span>Certificate timestamp: </span>
                <span>{verifiedNftCertificateTimestamp}</span>
                <br></br>
            </div>
            CheckApp it on the <a href="./../check/">Validator</a> (<a href="./../check/" target="_blank">new page</a>).
        </div>
    );

    const renderCertificateMakingButton = () => (
        <>
            <button
                onClick={makeCertificate}
                className="cta-button mint-button + (!nftMinted ? 'disable-button')"
                disabled={!nftMinted}
            >
                Create a certificate
            </button>
            <br></br>
            With {nftCreatorName} as NFT creator.
        </>
    );

    const renderInProgress = () => (
        <>
            <div>
                <img src={inProgressAnimation} width="200px" />
            </div>
            <div>The NFT is being recorded on the blockchain...</div>
        </>
    );

    return (
        <>
            {renderWelcomeMessage()}
            {(() => {
                if (nftMinted && !certificateMining && !certificateMined) {
                    return <>{renderCertificateMakingButton()}</>;
                } else {
                    if (!nftMinted && !certificateMining) {
                        return <>Available after minting the NFT.</>;
                    }
                }
            })()}

            {(() => {
                if (certificateMining && !certificateMined) {
                    return <>{renderInProgress()}</>;
                }
            })()}

            {(() => {
                if (certificateMined) {
                    return <>{renderResultSuccessMessage()}</>;
                }
            })()}
        </>
    );
};

CertificateMaker.propTypes = {
    nftMinted: PropTypes.bool.isRequired,
};

CertificateMaker.defaultProps = {};

export default CertificateMaker;
