/**
 * Beligent
 * Copyright 2023
 */

import React, { useEffect, useState } from "react";
import "./App.scss";

import { Routes, Route, Outlet, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AppHeader from "./components/AppHeader/AppHeader";
import AppFooter from "./components/AppFooter/AppFooter";
import HomePage from "./components/HomePage/HomePage";
import CheckApp from "./components/CheckApp/CheckApp/CheckApp.tsx";
import Platform from "./components/Platform/Platform/Platform.tsx";

/**
 * @function NoMatch
 * @description Renders the 404 page when the user tries to access a page that does not exist
 * @returns {JSX.Element} The 404 page
 */
function NoMatch() {
    return (
        <div className="screen-404">
            <span className="main-title blue-title very-large-text">
                Page not found
            </span>
            <p>
                <Link to="/">Back to home page</Link>
            </p>
        </div>
    );
}

function App() {
    const location = useLocation();
    const initUser = {
        publicAddress: "",
        connectedNetwork: "",
    };

    //const [currentUser, setCurrentUser] = useState(initUser);

    const getConnectedUserCallBack = (user) => {
        if (
            (!user || !user.publicAddress) &&
            user &&
            user.publicAddress !== ""
        ) {
            // console.log("User from connect App.js: ");
            setUser(user);
        }
    };

    const [user, setUser] = useState(null);
    const recordUser = (loggedInUser) => {
        // Callback for successful login from popup
        setUser(loggedInUser);
        console.log(loggedInUser);
        saveUserToSession(loggedInUser);
    };

    const saveUserToSession = (user) => {
        const timestamp = new Date().getTime(); // Get current time in milliseconds
        localStorage.setItem("user", JSON.stringify(user)); // Store user object
        localStorage.setItem("userTimestamp", timestamp); // Store timestamp
    };

    const checkSessionTimeout = () => {
        console.log("Checking session timeout");
        const user = JSON.parse(localStorage.getItem("user"));
        const timestamp = localStorage.getItem("userTimestamp");

        if (!user || !timestamp) {
            // If no user or timestamp exists, session is invalid
            return null;
        }

        const currentTime = new Date().getTime();
        const TEN_MINUTES = 10 * 60 * 1000; // 10 minutes in milliseconds

        if (currentTime - timestamp > TEN_MINUTES) {
            // If more than 10 minutes have passed, clear the session
            localStorage.removeItem("user");
            localStorage.removeItem("userTimestamp");
            console.log("Session expired");
            return null;
        }

        // If session is still valid, return the user
        return user;
    };

    useEffect(() => {
        const savedUser = checkSessionTimeout();
        if (savedUser) {
          setUser(savedUser);
        }
    }, []);

    // This is temporary as we don't retrieve the user's nfts on the blockchain.
    // We assume it is empty if page reloaded.
    // probably not used anymore but could be useful.
    // localStorage.setItem('_beligent_minting', JSON.stringify(false));
    // localStorage.setItem('_beligent_minted', JSON.stringify(false));

    function Layout() {
        return (
            <div className="app-container">
                <AppHeader user={user} recordUser={recordUser} />
                <Outlet />
                <AppFooter onSubmit={getConnectedUserCallBack} />
            </div>
        );
    }

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<HomePage user={user} />} />
                    <Route path="/check/" element={<CheckApp user={user} />} />
                    {/* :param? */}

                    <Route
                        path="/platform/"
                        element={<Platform user={user} />}
                    />
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </div>
    );
}

export default App;
