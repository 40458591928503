/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2023
 */

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./HomePage.scss";
import ContactButton from "../ContactButton/ContactButton";

const HomePage = ({ user }) => {
    return (
        <div className="HomePage">
            <div>
                <div className="hero gradient-background">
                    <div className="text-box">
                        <h1>Digital Product Passport</h1>
                        <h1 className="subtitle">Your Product's DNA</h1>
                        <hr />
                        <p className="product-description">
                            A digital Product Passport is an enhanced
                            Certificate of Authenticity which allows you to be
                            rock solid against counterfeiting and keep all the
                            information about the objects we love.
                            <br />
                            Add value to your products by increasing
                            sustainability and compliance for the benefit of
                            your customers and your brand.
                        </p>

                        <div></div>
                        <div>
                            <ContactButton></ContactButton>
                        </div>
                    </div>

                    <div className="phone-container">
                        <img src="./tel.png" className="frame img-fluid" />
                    </div>
                </div>
            </div>

            <div className="advantages-section">
                <div>
                    <span className="center main-title very-large-text advantages-headline">
                        Advantages for Brands and Customers
                    </span>
                </div>
                <p className="advantages-subtitle">
                    Traceability: A Digital Product Passport provides detailed
                    information about the product's DNA, materials, and
                    manufacturing processes.
                </p>
                <div className="advantages-container d-flex">
                    <div className="advantages row">
                        <div className="advantages column">
                            <ul className="advantages">
                                <li className="advantages-list-header">
                                    FOR YOUR BRANDS
                                </li>
                                <li className="advantages-list advantages-brands">
                                    <span className="advantages-category">
                                        Combat Counterfeiting
                                    </span>
                                    <p className="advantages-list-description">
                                        Digital Product Passports help verify
                                        the authenticity of products, reducing
                                        the risk of counterfeits and protecting
                                        the brand's integrity.
                                    </p>
                                </li>
                                <li className="advantages-list advantages-brands">
                                    <span className="advantages-category">
                                        Brand Reputation
                                    </span>
                                    <p className="advantages-list-description">
                                        By providing detailed and transparent
                                        product information, brands can build
                                        trust with consumers, improving their
                                        reputation and credibility.
                                    </p>
                                </li>
                                <li className="advantages-list advantages-brands">
                                    <span className="advantages-category">
                                        Simplified Compliance
                                    </span>
                                    <p className="advantages-list-description">
                                        Ensures adherence to various regulatory
                                        requirements regarding product
                                        information, safety standards, and
                                        sustainability disclosures, minimizing
                                        legal risks.
                                    </p>
                                </li>
                                <li className="advantages-list advantages-brands">
                                    <span className="advantages-category">
                                        Streamlined Operations
                                    </span>
                                    <p className="advantages-list-description">
                                        Digital Product Passports facilitate
                                        better tracking and management of
                                        products throughout the supply chain,
                                        enhancing operational efficiency.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="advantages column">
                            <ul className="advantages">
                                <li className="advantages-list-header">
                                    FOR YOUR CUSTOMER
                                </li>
                                <li className="advantages-list advantages-customers">
                                    <span className="advantages-category">
                                        Product Authenticity
                                    </span>
                                    <p className="advantages-list-description">
                                        Assures customers of the product’s
                                        authenticity, reducing the risk of
                                        purchasing counterfeit items.
                                    </p>
                                </li>
                                <li className="advantages-list advantages-customers">
                                    <span className="advantages-category">
                                        Informed Decisions
                                    </span>
                                    <p className="advantages-list-description">
                                        Customers gain access to detailed
                                        information about the product’s origin,
                                        materials, and manufacturing processes,
                                        enabling them to make informed
                                        purchasing decisions.
                                    </p>
                                </li>
                                <li className="advantages-list advantages-customers">
                                    <span className="advantages-category">
                                        Personalized Services
                                    </span>
                                    <p className="advantages-list-description">
                                        Customers receive tailored
                                        recommendations and services based on
                                        their product usage data.
                                    </p>
                                </li>
                                <li className="advantages-list advantages-customers">
                                    <span className="advantages-category">
                                        Eco-Friendly Products
                                    </span>
                                    <p className="advantages-list-description">
                                        Empowers customers to choose products
                                        that align with their values regarding
                                        sustainability and ethical sourcing
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <br />
                <div>
                    <button className="advantages-button">MORE</button>
                </div> */}
            </div>

            <div className="howitworks-section">
                <div className="gradient-background">
                    <div className="howitworks-container">
                        <span className="center main-title very-large-text howitworks-headline">
                            How it works
                        </span>

                        <p className="howitworks-subtitle">
                            We support you throughout the entire process,
                            offering tailored advice and creating your unique
                            digital passport to ensure authenticity and
                            traceability.
                        </p>

                        <div className="step-container">
                            <div className="step-box">
                                <div className="step-content">
                                    <div className="step-icon hide-below-768">
                                        <img
                                            src="./howitworks-icon1.svg"
                                            alt="Assess Icon"
                                        />
                                    </div>
                                    <div className="step-line hide-below-768"></div>
                                    <div className="step-text">
                                        <h3 className="step-title">Assess</h3>
                                        <p className="step-description">
                                            We start by studying your case and
                                            identifying the right solution
                                            tailored to your needs.
                                        </p>
                                    </div>
                                    <div className="step-number">
                                        <span>01.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="step-box">
                                <div className="step-content">
                                    <div className="step-icon hide-below-768">
                                        <img
                                            src="./howitworks-icon2.svg"
                                            alt="Register Icon"
                                        />
                                    </div>
                                    <div className="step-line hide-below-768"></div>
                                    <div className="step-text">
                                        <h3 className="step-title">Register</h3>
                                        <p className="step-description">
                                            Identify yourself and submit your
                                            asset’s details.
                                        </p>
                                    </div>
                                    <div className="step-number">
                                        <span>02.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="step-box">
                                <div className="step-content">
                                    <div className="step-icon hide-below-768">
                                        <img
                                            src="./howitworks-icon3.svg"
                                            alt="Record Icon"
                                        />
                                    </div>
                                    <div className="step-line hide-below-768"></div>
                                    <div className="step-text">
                                        <h3 className="step-title">Record</h3>
                                        <p className="step-description">
                                            Our system records your item on the
                                            blockchain, ensuring an immutable
                                            record of authenticity.
                                        </p>
                                    </div>
                                    <div className="step-number">
                                        <span>03.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="step-box">
                                <div className="step-content">
                                    <div className="step-icon hide-below-768">
                                        <img
                                            src="./howitworks-icon4.svg"
                                            alt="Verify Icon"
                                        />
                                    </div>
                                    <div className="step-line hide-below-768"></div>
                                    <div className="step-text">
                                        <h3 className="step-title">Verify</h3>
                                        <p className="step-description">
                                            You digital certificate can be
                                            verified globally in seconds!
                                        </p>
                                    </div>
                                    <div className="step-number">
                                        <span>04.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <br />
                        <div>
                            <button className="howitworks-button">MORE</button>
                        </div> */}
                    </div>
                </div>
            </div>

            {/* <div>
                <div>
                    <div className="section4">
                        <div>
                            <span className="center main-title very-large-text">
                                Benefits of on-chain authentication
                            </span>
                        </div>
                        <div className="section4-content">
                            <div className="block-spacing">
                                <div className="blue-title large-text subtitle">
                                    Enhanced security
                                </div>
                                <div>
                                    <p>
                                        With blockchain, each certificate is
                                        uniquely secure and impossible to
                                        counterfeit.
                                    </p>
                                </div>
                            </div>
                            <div className="block-spacing">
                                <div className="blue-title large-text subtitle">
                                    Global verification
                                </div>
                                <div>
                                    <p>
                                        Authenticate your items from anywhere in
                                        the world, instantly.
                                    </p>
                                </div>
                            </div>
                            <div className="block-spacing">
                                <div className="blue-title large-text subtitle">
                                    Lifetime record
                                </div>
                                <div>
                                    <p>
                                        Permanent record on the blockchain,
                                        providing lasting value and peace of
                                        mind.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />

                    <br />
                    <br />
                    <br />
                </div>
            </div>

            <div className="section3">
                <div className="gradient-background">
                    <div className="section3-container">
                        <div>
                            <span className="center main-title very-large-text">
                                Blockchain-powered certificates
                            </span>
                        </div>
                        <br />
                        <div className="large-text subtitle">
                            Unparalleled benefits
                        </div>
                        <div>
                            <p>
                                Our on-chain certificates offer unparalleled
                                security and convenience.
                                <br />
                                They eliminate the risk of counterfeit, reduce
                                reliance on physical documentation, and provide
                                a universally accessible and verifiable proof of
                                authenticity.
                            </p>
                        </div>

                        <br />
                        <div>
                            <div className="large-text subtitle">
                                Bringing trust to various industries
                            </div>
                            <div>
                                <p>
                                    From art and collectibles to luxury goods
                                    and important documents, our blockchain
                                    certification process is adaptable to a wide
                                    range of industries, offering them a new
                                    standard in authenticity verification.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="section5">
                <div className="section5-container">
                    <span className="center main-title very-large-text">
                        Case Study
                    </span>
                    <br />
                    <p className="case-study-subtitle">
                        Learn how to certify your products through innovative
                        collaborations in various areas, showcasing real-world
                        applications and success stories.
                    </p>
                    <br />
                    <div className="case-study-container">
                        <div className="row">
                            <div className="column">
                                <div class-name="img-case-study-a-m-logo">
                                    <img
                                        src="./a-m-logo.png"
                                        className="img-fluid a-m-logo"
                                    ></img>
                                </div>

                                <div className="blue-title large-text subtitle case-study-title">
                                    Alessandro Milani - Master Luthier
                                </div>

                                <div>
                                    <p className="case-study">
                                        Alessandro enhance his handcrafted
                                        violins with a modern touch by providing
                                        a digital Certificate of Authenticity,
                                        ensuring each instrument's authenticity
                                        and providing detailed information
                                        through a simple tap.
                                    </p>
                                </div>
                                <div>
                                    <ContactButton></ContactButton>
                                </div>
                            </div>
                            <div className="column hide-below-768">
                                <div className="img-case-study hide-below-768">
                                    <img
                                        src="./certified.png"
                                        className="img-fluid certified"
                                    />
                                </div>
                                <div className="img-case-study hide-below-768">
                                    <img
                                        src="./a-m-violin.png"
                                        className="img-fluid a-m-violin"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

HomePage.propTypes = {};

HomePage.defaultProps = {};

export default HomePage;
