/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2023
 */

import React, { useState } from "react";
import "./ContactButton.scss";

/**
 * ContactButton component.
 * A button that when clicked, copies the email address to the clipboard.
 */
const ContactButton = () => {
    const [imageVisible, setImageVisible] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const emailUser = "contact";
    const emailDomain = "beligent";
    const email = `${emailUser}@${emailDomain}.io`;

    /**
     * Copies the email address to the clipboard.
     * @returns {void}
     */
    const handleCopyEmail = () => {
        setImageVisible(false);
        setTimeout(() => setImageVisible(true), 500);

        navigator.clipboard.writeText(email).then(
            () => {
                setTimeout(() => setShowConfirmation(true), 500);
            },
            (err) => {
                console.error("Could not copy email: ", err);
            }
        );
    };

    return (
        <div className="content-row">
            <div className="block-center">
                <div className="">
                    <button
                        type="button"
                        onClick={handleCopyEmail}
                        className="btn action-button contact-button"
                    >
                        Contact us
                    </button>
                </div>
                {showConfirmation && (
                    <div className="center very-small-text confirmation">
                        Our address {email} has been copied to your clipboard.
                    </div>
                )}
            </div>
        </div>
    );
};

ContactButton.propTypes = {};

ContactButton.defaultProps = {};

export default ContactButton;
